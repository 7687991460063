import {QbElement} from "../qb-elements/qb-element";
import {DELETE} from "../qb-fetch";

class QbMessageBase extends QbElement {
    static shadow = {mode: 'closed', sheets: ["qb-elements"]}
    #messageNode
    #moreNode
    #to

    timeout = 20000

    constructor() {
        super();

        this.contentRoot.append(...this.defaultNodes);
        this.#to = setTimeout(() => {
            if(this.matches(":hover")) return;
            this.afterTimeout;
        }, this.timeout);

        this.addEventListener('keydown', e => {
            if(/Backspace|Delete/.test(e.key)) this.handleEvent_delete(e);
        });
        this.addEventListener('click', () => {
            this.focus();
        });
        this.contentRoot.adoptStyleSheets("slots");
        // this.addEventListener('touchstart', e => e.currentTarget === this && this.addClass("hover"));
        // this.addEventListener('touchend', e => e.currentTarget === this && this.removeClass("hover"));
        // this.addEventListener('mouseover', e => e.currentTarget === this && this.addClass("hover"));
        // this.addEventListener('mouseout', e => e.currentTarget === this && this.removeClass("hover"));
    }

    // connectedCallback() {
    //     super.connectedCallback();
    //     this.scrollIntoView();
    // }

    set new_message(value) {
        if(value && !this.hasClass("my-message")) this.addClass("new-message");
        this.parentNode.setClass(this.parentNode.querySelector(".new-message"), "new-message");
    }

    get defaultNodes() {
        return [
            this.iconNode,
            SLOT(),
            NAV(this.interaction)
        ].filter(n => n);
    }

    get deleteButton() {
        return BUTTON({class: 'delete', type: "button", tabindex: -1, events: [this, {click: 'delete'}]});
    }

    get afterTimeout() {
        this.removeClass("hover");
    }

    handleEvent_delete(e) {
        if(isNaN(this.itemid)) console.log("TODO Save receive status")
        this.close;
    }

    get height() { return this.getBoundingClientRect().height }

    get itemid() { return this.id.substring(1); }

    set itemid(value) { this.id = `M${value}`; }

    get close() {
        this.style.opacity = '1';
        this.style.transition = 'opacity 0.3s';
        this.addEventListener('transitionend', () => {
            this.delete();
        }, {once: true});
        setTimeout(() => this.style.opacity = '0', 10);
    }

    delete() {
        if(document.activeElement === this) (this.nextElementSibling || this.previousElementSibling)?.focus();
        this.remove();
    }

    set message(value) {
        this.textContent = value;
    }
}

class Notice extends QbMessageBase {
    get interaction() {
        return this.deleteButton;
    }

    get count() { return parseInt(this.iconNode.dataset.count) || 0 }

    exchange(messageNode) {
        messageNode.iconNode.dataset.count = (this?.count || 0) + 1;
        this.parentElement.append(messageNode);
        this.remove();
    }

    get iconNode() {
        return this.cache("iconNode", SPAN())
    }

    get itemid() { return this.id.substring(1); }

    set itemid(value) { return this.id = 'M' + value }
}

class Progress extends QbMessageBase {
    static nodeName = "qb-message-progress";

    set message(value) {
        console.log(value);
        if('pending' in value) {
            this.dataset.status = "queue";
            this.headline.textContent = _("Queued: %{num}", { num: value.pending});
            this.style.order = "100";
            return;
        }
        this.dataset.status = value.message;
        if('finished' in value) {
            this.progressNode.value = value.finished || 0;
            this.headline.dataset.progress = value.finished ? `${(parseFloat(value.finished) || 0) * 100}%` : '-';
        }
        if('src' in value) {
            let node;
            if(value.content_type.startsWith("audio")) node = this.querySelector("audio") || AUDIO({controls: true});
            else if(value.content_type.startsWith("video")) node = this.querySelector("video") || VIDEO({controls: true});
            else if(value.content_type.startsWith("image")) node = this.querySelector("img") || IMG();
            else return;
            node.src = value.src;
            if(!node.isConnected) {
                node.addEventListener("play", evt => {
                    document.querySelectorAll("audio,video").forEach(n => n !== evt.target && node.pause())
                });
                this.progressNode.replaceWith(node);
            }
        }
        this.headline.textContent = value.title || "N.N.";
        if(value.transform) this.headline.dataset.after = value.transform;
    }

    get defaultNodes() {
        return [
            this.headline = HEADER(),
            this.progressNode = PROGRESS({max: 1}),
            NAV(this.interaction)
        ].filter(n => n);
    }

    get interaction() {
        return this.deleteButton;
    }

    exchange(messageNode) {
        return this;
    }

}

const subclass = {
    Chat: class extends QbMessageBase {
        static nodeName = "qb-message-chat"

        exchange(messageNode) {
            this.replaceWith(messageNode);
        }


        get interaction() {
            return DETAILS(SUMMARY(), DIV(
                this.deleteButton,
                BUTTON({class: 'reply', type: "button", tabindex: -1, events: [this, {click: 'reply'}]}),
                BUTTON({class: 'edit', type: "button", tabindex: -1, events: [this, {click: 'edit'}]}),
            ))
        }

        get iconNode() {
            return this.cache("iconNode", cT("qb-player", {class: "avatar"}));
        }

        handleEvent_edit(evt) {
            this.parentElement.edit(this.textContent, this);
        }

        handleEvent_reply(evt) {
            const reply = getSelection().toString() || this.textContent.substring(0, 20);
            this.parentElement.reply(reply, this);
        }

        delete() {
            DELETE("/chat", this.itemid).result({json: data => data === "ok" && super.delete()});
        }

        set reference(ref) {
            if(!ref) return;
            this.addClass("reply");
            this.contentRoot.prepend(cT("qb-player", {class: "reply avatar", id: `R${ref.itemid}`}, ref.text));
        }
    },
    Notice: class extends Notice {
        static nodeName = "qb-message-notice";
        timeout = 5000;

        get afterTimeout() {
            this.close;
        }
    },
    Warn: class extends Notice {
        static nodeName = "qb-message-warn";
    },
    Exception: class extends Notice {
        static nodeName = "qb-message-exception";
    },
    Error: class extends Notice {
        static nodeName = "qb-message-error";
    },
    Progress: Progress
}

Object.values(subclass).forEach(c => c.register);

export function QbMessage({itemtype, ...args}) {
    const nodeName = subclass[itemtype]?.nodeName || "qb-message-notice";
    return cT(nodeName, args);
}